/* StreamerDashboard.module.css */

.dashboardContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #f5f5f5;
    background-color: #1e1e2f; /* Dark background */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.title {
    color: #e63946; /* Accent color */
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.section {
    margin-top: 20px;
    padding: 20px;
    background-color: #2c2c3b;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.sectionHeader {
    color: #f5f5f5;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.textField {
    margin-bottom: 15px;
    width: 100%;
}

.button {
    background-color: #e63946;
    color: #f5f5f5;
    font-weight: bold;
    width: 100%;
    margin-top: 10px;
}

.button:hover {
    background-color: #c72533;
}

.select {
    width: 100%;
    margin-top: 10px;
    background-color: #3c3c4c;
    color: #f5f5f5;
}

.card {
    margin-top: 20px;
    padding: 15px;
    background-color: #333344;
    border-radius: 8px;
}

.historyEntry {
    color: #bdbdbd;
    padding: 8px;
    border-bottom: 1px solid #444;
}

.historyEntry:last-child {
    border-bottom: none;
}

.snackbar {
    bottom: 50px;
}
