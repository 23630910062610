/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: #0d0d0d; /* Dark theme background */
    color: #f5f5f5;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

/* Accent Colors */
:root {
    --primary-accent: #ff6f61; /* Vibrant accent color for main elements */
    --secondary-accent: #1db954; /* Spotify green for music vibes */
    --text-light: #e1e1e1;
    --text-muted: #9e9e9e;
}

/* Main Container Styles */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Background and Foreground Design */
body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 111, 97, 0.15), rgba(29, 185, 84, 0.15));
    z-index: -1;
    opacity: 0.9;
}

/* Custom Sidebar */
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100vh;
    background: #1b1b1b;
    color: #fff;
    padding: 20px;
    transition: width 0.3s;
}

.sidebar .menu {
    list-style: none;
    margin-top: 50px;
}

.sidebar .menu-item {
    margin-bottom: 15px;
}

.sidebar .menu-item a {
    color: var(--text-light);
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    transition: all 0.3s;
}

.sidebar .menu-item a:hover {
    background: var(--primary-accent);
    color: #fff;
}

/* Header */
header {
    background: #1b1b1b;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    letter-spacing: 1px;
    border-bottom: 2px solid var(--primary-accent);
}

/* Custom Button Styles */
button, .btn {
    background: var(--primary-accent);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 16px;
    font-weight: bold;
}

button:hover, .btn:hover {
    background: var(--secondary-accent);
}

/* Typography Styles */
h1, h2, h3, h4, h5, h6 {
    color: var(--primary-accent);
    font-weight: bold;
}

p {
    color: var(--text-light);
    margin-bottom: 15px;
}

/* Forms */
input[type="text"], input[type="email"], input[type="password"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid var(--primary-accent);
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #2b2b2b;
    color: #fff;
    transition: all 0.3s;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus {
    border-color: var(--secondary-accent);
    outline: none;
}

/* Card Styles */
.card {
    background: #1b1b1b;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
}

/* Animation for dynamic vibe */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}

.pulse {
    animation: pulse 2s infinite;
}

/* Hover Effects */
.card:hover, .menu-item a:hover {
    transition: 0.3s ease;
}

/* Music Wave Animation */
.music-wave {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-top: 20px;
}

.music-wave div {
    width: 4px;
    height: 30px;
    background-color: var(--secondary-accent);
    animation: wave 1s infinite ease-in-out;
}

.music-wave div:nth-child(2) {
    animation-delay: 0.1s;
}

.music-wave div:nth-child(3) {
    animation-delay: 0.2s;
}

.music-wave div:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes wave {
    0%, 100% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(2);
    }
}

/* Footer */
footer {
    background: #1b1b1b;
    color: var(--text-muted);
    text-align: center;
    padding: 15px;
    font-size: 14px;
    border-top: 2px solid var(--primary-accent);
}

footer a {
    color: var(--primary-accent);
    text-decoration: none;
}

footer a:hover {
    color: var(--secondary-accent);
}

/* Responsive Design */
@media (max-width: 768px) {
    .sidebar {
        width: 80px;
    }

    .container {
        padding: 15px;
    }

    header {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    header {
        font-size: 18px;
    }
    
    button, .btn {
        padding: 8px 15px;
        font-size: 14px;
    }
}
