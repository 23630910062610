.sidebar {
    width: 200px;
    background-color: #111;
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.sidebar h2 {
    color: #00c853;
    margin-bottom: 20px;
}

.sidebar nav a {
    color: #fff;
    text-decoration: none;
    margin: 10px 0;
}

.sidebar nav a:hover {
    color: #00c853;
}
