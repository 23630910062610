/* verifiedStreamers.css */

/* Basic styling for the creators grid */
.creators-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.creator-card {
    position: relative;
    width: 150px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: #1e1e1e;
    color: #fff;
    transition: transform 0.2s ease;
}

.creator-card:hover {
    transform: scale(1.05);
}

.profile-picture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
}

.verified-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #00c853;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
}
