/* Main background and font colors */
body {
    background-color: #1b1b1b;
    color: #e5e5e5;
    font-family: Arial, sans-serif;
}

/* Page container */
.creators-container {
    width: 90%;
    margin: auto;
    padding-top: 20px;
}

/* Title styling */
h3 {
    color: #e63946;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

/* Tab styles */
.MuiTab-root {
    color: #e63946;
    font-weight: bold;
}

.Mui-selected {
    color: #32CD32 !important;
}

/* Search bar styling */
.MuiTextField-root input {
    background-color: #333;
    color: #e5e5e5;
    border-radius: 5px;
    padding: 10px;
}

/* Grid container for creators */
.creators-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

/* Creator card styling */
.creator-card {
    position: relative;
    background-color: #252525;
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    color: #e5e5e5;
    cursor: pointer;
    transition: transform 0.3s ease, border-color 0.3s ease;
}

.creator-card:hover {
    transform: scale(1.05);
    border: 1px solid #e63946;
}

.profile-picture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

/* Verified badge styling */
.verified-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #32CD32;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Creator info */
.creator-info h3 {
    font-size: 18px;
    margin: 5px 0;
    color: #e5e5e5;
}

.creator-info p {
    font-size: 14px;
    color: #b5b5b5;
}
